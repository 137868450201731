import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`Wizdough WizBot for NinjaTrader 8`}</p>
          <p>{`WizBot is an automated trading and strategy building tool designed for NinjaTrader 8. It provides an easy way to build, test, and execute custom trading strategies without any coding required.`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          width: "100%",
          padding: "1rem 35%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "162px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "112.34567901234568%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEiklEQVQ4y3WU628UZRSHByFaytLtbuneu5fZy8zsZfbWbmsvaQ2WXmi3paCiQQKkpS1tLKWt5SJRvygmYuIHgZCARCgtmhj/iiISDaD4Tf8T83hmd7001g9P3vf9nfM7+87OmaOUOnq4/P4HLCyc5c2jb/Hu6ipz8/OcOHmS0eEh1GiMeDxONBYVYrV1e6w8pa3UxdnFFebPzDM9NcvK0grLS6ucmZ6lPCQFI1IwKgVVKaZG/yZmnSPRLcRiUrBQbCVmJNDSBoaZJp5OENZVoknRsknBQMvoFd0ilo7XSKBnkjUMdDOJkUqixMc0dl/cjW1lD3XLL+F9L4j2YQLfJTeui268lzzYVxsIXg7juegR3Sd4CV1uoX6lvspyPXUX6ogMqyih1yMonyooHwsfKcRuZpn45jXK9wcob0wwvlEmcVOj9U4vPV/1cXB9hCMPRum504FyRaliea8q+A75UTJ9JqGJAOHxIOFDQdSyn/CIl3DZR2w8VCEi50g5UNFCIx5C5Wo8YuWKx/KGDregd+koy1GD33Y28POLDp7vtPOkOcwTj8qzBi9PGzyy+vhJTfPU4atq+0I8a5S9o4WnzgDPd9l5Jt7fxbsQjMlNQxFQlH9I6VAegQMDMLQfQkEYK/PHkTKMiDYyDAOiHzoMeX2L94pfHvlYMsMtj5frXj83vD6uub2Chy9Eu+YTzVfVrwdaKlj7G74AN/y1vRUT723JfyOuoZit7aj5IlEhkiuSfLmLVGc3ZncvRlu7aIVKLGqtErdy1VyN/L9pxcjmUYqFNsxMmqyZQdMMjr19nMVzS5y/cJ7D8oiGpkvMlJzMNqS3kDWz8qXk2ykabRSTJQqytmc76Sz00JnvppTpkL+pWI3XyMk5b7Ru0aqUyKULKNpgEvt0I45JJ42C+7QH15SLfVPNOCebcE97cEw14RTskw78M/6K1ih7y+OYclZW+0wj0f3ylsNWY1+tNag0dvZWkbGNQUbXDlC6lUO9rlL4soOjX4/SebtE390+iY8xvj6A+3Nbtakt72cKgQl5y+pQih1zTeyaaeaFmX3Y58K4zmq4FjScC1Fscy2439HxnksRWsrRMBfEvaDjXtTZM+8XT9W7Y76Jln4ZDqe0NN82ull3enng8HDP5edeQxNre52s1zey5gpw1+Vjrc7GXZucmwPcczRzf/deNmxNPBDfusPLd1LjuKpt09jy+hk7CDILKcleVaXRR6Ffzl0d0PuKxKXxB6W5s+kt3k/8AZTFuM4v9fU8tu3lx3obj6RJNxMJHsowfRQM8rjZxcOIyqaW5KGcHwWCbMrc25R2+r4lIJ49/CDeX6XGfFimTVYaOy69mBDihVZ0aVItZaJKgYieIm7mZR7mZF6aaNbeIp2tIvtEzWuRyhZqjZ3KyO1NUvIZDg+XmZ09w+lTJ5g8eYre7h5SRopsJospOf9BvBXSUkN+WCkWS5hmrkJGhPaOLvb3D9D/aj9Dg8O0tZVEl2Jym7/ytidfQbGSk8m03E5IpTH0JAn5yOMWsQSG3C4tN/g75/8QbzKZ4k/h3fd4bQ1OsgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "chart-example",
              "title": "chart-example",
              "src": "/static/c550d521e04f43208a4175f9cb2328bf/b195a/wizbot-buttons.png",
              "srcSet": ["/static/c550d521e04f43208a4175f9cb2328bf/b195a/wizbot-buttons.png 162w"],
              "sizes": "(max-width: 162px) 100vw, 162px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          space: "0"
        }}>
One license per machine:
          <br />
          <Checkout id="2" lnk="https://drive.google.com/uc?id=1k47xeeRQ4Ovj6FPeTDh87VOvpHnkJCRX&export=download" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> Download</div>free beta</Checkout>
          <br />
          <br />
          <p>{`By purchasing from this website, you acknowledge that you understand and accept the following agreements:`}</p>
          <ul>
            <li parentName="ul">{`No Refunds Policy;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/terms/"
              }}>{`Terms and Conditions`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/risk/"
              }}>{`Full Risk Disclosure`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/software/"
              }}>{`Software Agreement`}</a>{`.`}<br />
              <br />
              <br /></li>
          </ul>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#343444',
            padding: '1rem',
            width: '100%'
          }}>
  <a href="https://drive.google.com/uc?id=1k47xeeRQ4Ovj6FPeTDh87VOvpHnkJCRX&export=download" style={{
              color: '#fff',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center'
            }}>
    <div style={{
                width: '30px',
                marginRight: '1rem'
              }}>
      <img src="/images/download-blue.png" alt="download-blue" title="download-blue" style={{
                  width: '100%'
                }} />
    </div>
    <div>
      Download free beta
    </div>
  </a>
          </div>
          <br />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#343444',
            padding: '1rem',
            width: '100%'
          }}>
    <div style={{
              width: '30px',
              marginRight: '1rem'
            }}>
      <img src="/images/document-blue.png" alt="download-blue" title="download-blue" style={{
                width: '100%'
              }} />
    </div>
    <div>
              <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1bMdaacMrSprJXcSvUhdOzWr4cZRay7aczLbicvsFdnM">Read and Comment on Full Documentation (Google Docs)</a>
              <br />
              <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1bMdaacMrSprJXcSvUhdOzWr4cZRay7aczLbicvsFdnM/export?format=pdf">Download Full Documentation (PDF)</a>
    </div>
          </div>
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={4} colLg={7} mdxType="Column">
        <h2>{`Key Features`}</h2>
        <ul>
          <li parentName="ul">{`Strategy Builder - Easily create strategies through NinjaTrader’s interface. Add indicators, rules, position sizing etc.`}</li>
          <li parentName="ul">{`Accurate Backtesting - Leverages NinjaTrader’s engine for accurate historical testing with detailed metrics.`}</li>
          <li parentName="ul">{`Optimization - Use NinjaTrader optimization to automatically find best parameters.`}</li>
          <li parentName="ul">{`Automation - Fully automate strategy trading and execution.`}</li>
          <li parentName="ul">{`Customization - Flexible options to tailor strategies to your style.`}</li>
          <li parentName="ul">{`Ease of Use - Intuitive interface makes WizBot simple yet powerful.`}</li>
        </ul>
        <p>{`Strategy Benefits`}</p>
        <ul>
          <li parentName="ul">{`Genuine server-side OCO orders prevent losing the ability to cancel orders during disconnects, if broker supports it.`}</li>
          <li parentName="ul">{`Backtesting close to real-time trading with features like Tick Replay and Enforce Partial Fills.`}</li>
          <li parentName="ul">{`Optimization finds best performing parameters.`}</li>
          <li parentName="ul">{`Protection against stop losses being placed too close to current price during volatility.`}</li>
        </ul>
        <p>{`WizBot seamlessly integrates into NinjaTrader 8 for easy strategy creation, testing, and automation.`}</p>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
        <h3>{`Video Manual`}</h3>
        <div style={{
          justifyContent: 'flex-end',
          display: "flex"
        }}>
          <br />
          <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/SzTyYfUr3Pc?playsinline=1" frameBorder="0" allowFullScreen />
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '1rem',
          width: "48%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "260.06944444444446%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAA0CAIAAAAFR49sAAAACXBIWXMAAAsSAAALEgHS3X78AAAESklEQVRIx5WX23LiRhCGeQ4bMEIHhA7ohM6IkyFxLpKLdcrvsLn0K9hVzo3feb/RAKuVBE66uiiQp6f//ufvHnmw8Ly5OR+NRg99Nr5uuq4PLMtKkmQ2M8fjnmBloijKtNenU3XAiiiKFosFq8jf3Js/HQ9Pf/35bV3tq4avVrvt5lgU1UBVVYLDMDAMowPtIQyXy2VqWa5tL5ruOJ7jLAaapvl+4Hv+bDb7Je94NJlMnp5+/1bb3w17fn5+eXk5Ho8CdhiGjuOwtAmb7xTy+vr6/v7+9vb2b8M+Pj4+Pz+/f/9nQIzv+yCHuU7wFFB2bU7H5nNLZJZ/pvhfQI+ADafq3d3dfZ+xRmQOgoClw+GwlZnTEESMhvzqOoEi2PM8MM/n83rpSG40HLL3QxxnYRAH/rLloR+7rieCOWS/NiDYDTPNuW27moaWjF4/wYZwOCM5gBVFqkoUTLCua9M+Y1NBGJlBzicpoI0nEjywd9vD8fBHWWxX5a7p6+oxS8tTMOa6blgbW0jmAFWWG9Yhz5Zv1o9xnItgWS2fRPLlcuAcFRqk8tmszw1zwKILYYAnP/WcG2OSZ6Teh2ESwHnDeeK6vmgMkTYIXMfhfJo6AbamGWf++lqSs6Wn8rxI0tQ0Z+dDHsrxIAXfa0IkZPB8H62C2a+/cGCUTbcxCRgSrSZv4hqACdCO6zquQ/FwRtkohO+c5DJK42XGZ8t56HnhwLJtxlBVVdBFHex3GkCKMhGmXDMWixmGtvIsI5vsh4u1fvbUTKeGQZimKRoj6/g/mwjmqCAqzTKlM0nAjPrHvWO1rksoDJ45akhutTQnta52iCRNyixdNbws8nUSZ6dgqS1UzX6XWU9wkVfVSszaljOAIfxnS3I2kMc5Xwb4OfMhz9dFsWk68QmNIScJaWVLXFrqHLzfrg8lq8tt04GTxEXdGB6tY5IQVV26QvK02Tzud791gunTvehntI0q4zhG7B3CHgxjBuFIretiDOmGQcVFUWqa2rmrhMLkji3jGVtz3ejUjEJVddoNppZbIiE9VF0JRgnTW8GQRHCe58BuDQORWcC+H/aZgC0IY67EiW1Zcr9m5msT++fc9mt5Ujl3ceO6E9qOwgTn0mg5DxfMsOb0BMVFm/KKVVWNO+0qbAgTA8jzWM2FKO6oerWETfKHKya6inOO6vcKeW/U0ztCrbJ+Mvee82kYAHW5RGBLTqvIC75kecb9IYFJhV1xcVeN5U3v1akXNXlInWAw03q8+NBYLedhirbN+Zx+JLVdtxRAeC1ivMi5bZrWNWcqDxj8pFpGETU0yxM11yK5vyESqopgqL5rWm+NktJbbBuCMGGGofc1xvT29NRkP/PZfv/7sjGgB3WhbfH62NNVyhfBsFUUhWT4/8EWhEUh2upeFlKeN/tZ1yPxJhTCXCf468xomxlWdJNzzlMRfP1+RolQTeqpmPVt2Df+UyD4B3ma4KGS3QDSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "long-chart",
              "title": "long-chart",
              "src": "/static/654cbe82a1a9031b5a404a7a39e28ae7/102e5/wizbot-settings.png",
              "srcSet": ["/static/654cbe82a1a9031b5a404a7a39e28ae7/7fc1e/wizbot-settings.png 288w", "/static/654cbe82a1a9031b5a404a7a39e28ae7/a5df1/wizbot-settings.png 576w", "/static/654cbe82a1a9031b5a404a7a39e28ae7/102e5/wizbot-settings.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Do you have questions or feedback?`}</p>
        <p>{`Need technology or a new concept for your business? `}</p>
        <p>{`Contact us, and our team will get back to you.`}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below, and let us discuss your idea. Your details will be kept confidential.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      